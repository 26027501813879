import { createApi } from "@reduxjs/toolkit/query/react";
import { ApiConstant } from "../../constants/ApiConstants";
import baseQuery from "./baseQuery";
import {
  SearchParam,
  AllSeminarInfo,
  CreateSeminarData,
  EmployerInfoList,
  GetSeminarInfo,
  SeminarList,
  UpdateSeminarData,
  AddOrgQuery,
} from "types/seminar";

export const ADMIN_API_REDUCER_KEY = "seminarApi";

export const seminarApi = createApi({
  reducerPath: ADMIN_API_REDUCER_KEY,
  baseQuery: baseQuery,
  tagTypes: ["Seminar"],
  endpoints: (builder) => ({
    getSeminarList: builder.query<SeminarList, Partial<SearchParam>>({
      query: (args: SearchParam) => {
        const { pageNo = 1, size = 30, field, searchText = "", filter } = args;
        const body = { pageNo, size, field };
        return {
          url: ApiConstant.SEMINAR_LISTING,
          method: "POST",
          body: filter?.length ? { ...body, filter } : { ...body, searchText },
        };
      },
      providesTags: ["Seminar"],
    }),
    getSeminarById: builder.query<GetSeminarInfo, string>({
      query: (id: string) => {
        return {
          url: `${ApiConstant.SEMINAR_BY_ID}/${id}`,
          method: "GET",
        };
      },
      providesTags: ["Seminar"],
    }),
    createSeminar: builder.mutation<
      CreateSeminarData,
      Partial<AllSeminarInfo>
    >({
      query(body) {
        return {
          url: ApiConstant.CREATE_SEMINAR,
          method: "POST",
          body,
        };
      },
      transformErrorResponse: (response: {
        data: { error: string; message: string };
        status: number;
      }): string => response.data.error,
      invalidatesTags: ["Seminar"],
    }),
    getEmployerByQuery: builder.query<EmployerInfoList, AddOrgQuery>({
      query: (body) => {
        return {
          url: ApiConstant.GET_EMPLOYER_BY_QUERY,
          method: "POST",
          body,
        };
      },
      transformErrorResponse: (response: {
        data: { error: string; message: string };
        status: number;
      }): string => response.data.error,
    }),
    editSeminar: builder.mutation<
      UpdateSeminarData,
      Partial<AllSeminarInfo> & Pick<AllSeminarInfo, "ID">
    >({
      query(body) {
        const { ID, ...rest } = body;
        return {
          url: ApiConstant.UPDATE_SEMINAR(ID),
          method: "PATCH",
          body: rest,
        };
      },
      transformErrorResponse: (response: {
        data: { error: string; message: string };
        status: number;
      }) => response.data.error,
      invalidatesTags: ["Seminar"],
    }),
  }),
});

export const {
  useGetSeminarListQuery,
  useGetSeminarByIdQuery,
  useCreateSeminarMutation,
  useEditSeminarMutation,
  useGetEmployerByQueryQuery,
  useLazyGetEmployerByQueryQuery
} = seminarApi;
